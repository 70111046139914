import Lottie from 'lottie-web';

import 'reset-css'
import '../sass/style.scss'

document.getElementById('menu-icon')?.addEventListener('click', () => {
  document.body.classList.toggle('menu-open')
})

Lottie.loadAnimation({
  container: document.getElementById('anim') as Element,
  renderer: 'svg',
  loop: true,
  autoplay: true,
  name: 'nice_anim',
  path: '/core/wp-content/themes/officenice/json/nice_anim.json',
});
